<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view v-else-if="!subPermission.store || !subPermission.update" />
  <div v-else>
    <validation-observer ref="ACARForm">
      <b-form>
        <b-card :title="$t('g.addRequest')">
          <b-row>
            <!-- Request Type -->
            <b-col md="4">
              <b-form-group :label="$t('g.requestType')">
                <validation-provider
                  #default="{ errors }"
                  name="request type"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.correct_action_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="correctionType"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Request Type -->
            <!-- Raised by -->
            <b-col md="4">
              <b-form-group :label="$t('g.raisedBy')">
                <validation-provider
                  #default="{ errors }"
                  name="raised by"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.raised_by"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.EnterTheRaisedByHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- ** Raised by -->

            <!-- Assigned to -->
            <b-col md="4">
              <b-form-group :label="$t('g.assignedTo')">
                <validation-provider
                  #default="{ errors }"
                  name="assigned to"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.assigned_by"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.EnterTheAssignedToHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- ** Assigned to -->

            <!-- ccording to the Risk management Procedure Risk Level -->
            <b-col md="4">
              <b-form-group :label="$t('g.riskLevel')">
                <validation-provider
                  #default="{ errors }"
                  name="Risk level"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.risk_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="riskType"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** ccording to the Risk management Procedure Risk Level -->

            <b-col md="4">
              <b-form-group :label="$t('g.request_date')">
                <validation-provider
                  #default="{ errors }"
                  name="closing date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.request_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Expected closing date of the corrective action -->
            <b-col md="4">
              <b-form-group :label="$t('g.closingDate')">
                <validation-provider
                  #default="{ errors }"
                  name="closing date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.closing_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Expected closing date of the corrective action -->

            <!-- Root cause analysis required -->
            <b-col md="4">
              <b-form-group :label="$t('g.rootCauseAnalysisRequired')">
                <validation-provider
                  #default="{ errors }"
                  name="root cause analysis required"
                  rules="required"
                >
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="form_data.root_analysis_required"
                      name="some-radio9"
                      :value="1"
                      :state="errors.length > 0 ? false : null"
                      class="custom-control-success"
                    >
                      {{ $t("g.yes") }}</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form_data.root_analysis_required"
                      name="some-radio9"
                      :value="0"
                      :state="errors.length > 0 ? false : null"
                      class="custom-control-danger mr-1 ml-1"
                    >
                      {{ $t("g.no") }}</b-form-checkbox
                    >
                  </div>

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Root cause analysis required -->
            <!-- Comments on effectiveness of action taken -->
            <b-col md="4">
              <b-form-group
                :label="$t('g.CommentsOnEffectivenessOfActionTaken')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="comments on effectiveness of action taken"
                  rules="required"
                >
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="form_data.comments_action_taken"
                      name="some-radio9"
                      :value="1"
                      :state="errors.length > 0 ? false : null"
                      class="custom-control-success"
                    >
                      {{ $t("g.yes") }}</b-form-checkbox
                    >

                    <b-form-checkbox
                      v-model="form_data.comments_action_taken"
                      name="some-radio9"
                      :value="0"
                      :state="errors.length > 0 ? false : null"
                      class="custom-control-danger mr-1 ml-1"
                    >
                      {{ $t("g.no") }}</b-form-checkbox
                    >
                  </div>

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Comments on effectiveness of action taken -->

            <!-- Description of NC -->
            <b-col md="12">
              <b-form-group :label="$t('g.descriptionOfNC')">
                <validation-provider
                  #default="{ errors }"
                  name="description of NC"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheDescriptionOfNCHere')
                    "
                    v-model="form_data.nc_description"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Description of NC -->
            <!-- Underlying / root cause -->
            <b-col md="12">
              <b-form-group :label="$t('g.rootCause')">
                <validation-provider
                  #default="{ errors }"
                  name="root cause"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="$t('g.placeholder.enterTheRootCauseHere')"
                    v-model="form_data.root_cause"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Underlying / root cause -->
            <!-- Proposed immediate action -->
            <b-col md="12">
              <b-form-group :label="$t('g.proposedImmediateAction')">
                <validation-provider
                  #default="{ errors }"
                  name="proposed immediate action"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheProposedImmediateActionHere')
                    "
                    v-model="form_data.proposed_action"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Proposed immediate action -->

            <!-- Corrective action -->
            <b-col md="12">
              <b-form-group :label="$t('g.correctiveAction')">
                <validation-provider
                  #default="{ errors }"
                  name="corrective action"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheCorrectiveActionHere')
                    "
                    v-model="form_data.corrective_action"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Corrective action -->
            <!-- Submit Button -->
            <b-col md="12">
              <b-button variant="primary" @click.prevent="validationForm">
                {{ $t("g.submit") }}
              </b-button>
            </b-col>

            <!-- ** Submit Button -->
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      serviceRequestId: null,
      techEvaluationId: null,
      form_data: {
        risk_type_id: null,
        correct_action_type_id: null,
        raised_by: null,
        assigned_by: null,
        nc_description: null,
        root_analysis_required: null,
        root_cause: null,
        corrective_action: null,
        proposed_action: null,
        closing_date: null,
        comments_action_taken: null,
        request_date: null,
      },
      correctionType: [],
      riskType: [],

      request_type_options: [
        {
          value: null,
          text: this.$t("g.selectinstrumentSituation"),
          disabled: true,
        },
        this.$t("g.contractorFault"),
        this.$t("g.complaintsOfExternalParties"),
        this.$t("g.poorEfficiencyOfIndividuals"),
        this.$t("g.wrongInstructionsInfraction"),
        this.$t("g.operatorFault"),
        this.$t("g.NCFromAB"),
        this.$t("g.Observation"),
        this.$t("g.environmentalIncident"),
        this.$t("g.unControlOfDocument"),
        this.$t("g.systemFailure"),
        this.$t("g.NCReferences"),
        this.$t("g.otherMentioned"),
      ],
      subPermission: {},
      isLoading: true,
      isError: null,
    };
  },

  created() {
    this.serviceRequestId = this.$route.params.id;
    this.techEvaluationId = this.$route.params.tech_id;
  },

  beforeMount() {
    this.getCorrectionType();
    this.getRiskType();
  },

  methods: {
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
      }
    },

    getCorrectionType() {
      this.$http
        .get(`admin/correctActionTypes`)
        .then((res) => {
          this.correctionType = res.data.data;
        })
        .catch((err) => console.log(err));
    },

    getRiskType() {
      this.$http
        .get(`admin/riskTypes`)
        .then((res) => {
          this.riskType = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "correctActionTypes");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },

    submitForm() {
      console.log(this.form_data);
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }

      this.$http
        .post(
          `admin/serviceRequests/${this.serviceRequestId}/techEvaluations/${this.techEvaluationId}/correctActionRequests`,
          formData
        )
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );

          setTimeout(() => {
            this.$router.push({ name: "ViewTechEvaluation" });
          }, 500);
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },

    validationForm() {
      this.$refs.ACARForm.validate().then((success) => {
        if (success) {
          this.submitForm();
          // this.clearForm();
          // this.$refs.ACARForm.reset();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>

<style></style>
