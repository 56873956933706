var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('div',[_c('validation-observer',{ref:"ACARForm"},[_c('b-form',[_c('b-card',{attrs:{"title":_vm.$t('g.addRequest')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.requestType')}},[_c('validation-provider',{attrs:{"name":"request type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.correctionType,"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.correct_action_type_id),callback:function ($$v) {_vm.$set(_vm.form_data, "correct_action_type_id", $$v)},expression:"form_data.correct_action_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.raisedBy')}},[_c('validation-provider',{attrs:{"name":"raised by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheRaisedByHere')},model:{value:(_vm.form_data.raised_by),callback:function ($$v) {_vm.$set(_vm.form_data, "raised_by", $$v)},expression:"form_data.raised_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.assignedTo')}},[_c('validation-provider',{attrs:{"name":"assigned to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.EnterTheAssignedToHere')},model:{value:(_vm.form_data.assigned_by),callback:function ($$v) {_vm.$set(_vm.form_data, "assigned_by", $$v)},expression:"form_data.assigned_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.riskLevel')}},[_c('validation-provider',{attrs:{"name":"Risk level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.riskType,"state":errors.length > 0 ? false : null,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.risk_type_id),callback:function ($$v) {_vm.$set(_vm.form_data, "risk_type_id", $$v)},expression:"form_data.risk_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.request_date')}},[_c('validation-provider',{attrs:{"name":"closing date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.request_date),callback:function ($$v) {_vm.$set(_vm.form_data, "request_date", $$v)},expression:"form_data.request_date"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.closingDate')}},[_c('validation-provider',{attrs:{"name":"closing date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.closing_date),callback:function ($$v) {_vm.$set(_vm.form_data, "closing_date", $$v)},expression:"form_data.closing_date"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.rootCauseAnalysisRequired')}},[_c('validation-provider',{attrs:{"name":"root cause analysis required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"some-radio9","value":1,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.root_analysis_required),callback:function ($$v) {_vm.$set(_vm.form_data, "root_analysis_required", $$v)},expression:"form_data.root_analysis_required"}},[_vm._v(" "+_vm._s(_vm.$t("g.yes")))]),_c('b-form-checkbox',{staticClass:"custom-control-danger mr-1 ml-1",attrs:{"name":"some-radio9","value":0,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.root_analysis_required),callback:function ($$v) {_vm.$set(_vm.form_data, "root_analysis_required", $$v)},expression:"form_data.root_analysis_required"}},[_vm._v(" "+_vm._s(_vm.$t("g.no")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.CommentsOnEffectivenessOfActionTaken')}},[_c('validation-provider',{attrs:{"name":"comments on effectiveness of action taken","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"some-radio9","value":1,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.comments_action_taken),callback:function ($$v) {_vm.$set(_vm.form_data, "comments_action_taken", $$v)},expression:"form_data.comments_action_taken"}},[_vm._v(" "+_vm._s(_vm.$t("g.yes")))]),_c('b-form-checkbox',{staticClass:"custom-control-danger mr-1 ml-1",attrs:{"name":"some-radio9","value":0,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.comments_action_taken),callback:function ($$v) {_vm.$set(_vm.form_data, "comments_action_taken", $$v)},expression:"form_data.comments_action_taken"}},[_vm._v(" "+_vm._s(_vm.$t("g.no")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.descriptionOfNC')}},[_c('validation-provider',{attrs:{"name":"description of NC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheDescriptionOfNCHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.nc_description),callback:function ($$v) {_vm.$set(_vm.form_data, "nc_description", $$v)},expression:"form_data.nc_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.rootCause')}},[_c('validation-provider',{attrs:{"name":"root cause","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheRootCauseHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.root_cause),callback:function ($$v) {_vm.$set(_vm.form_data, "root_cause", $$v)},expression:"form_data.root_cause"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.proposedImmediateAction')}},[_c('validation-provider',{attrs:{"name":"proposed immediate action","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheProposedImmediateActionHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.proposed_action),callback:function ($$v) {_vm.$set(_vm.form_data, "proposed_action", $$v)},expression:"form_data.proposed_action"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.correctiveAction')}},[_c('validation-provider',{attrs:{"name":"corrective action","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheCorrectiveActionHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.corrective_action),callback:function ($$v) {_vm.$set(_vm.form_data, "corrective_action", $$v)},expression:"form_data.corrective_action"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }